import { navigate } from 'gatsby';

import { ErrorMessages } from '@domain/auth';

export const prepHandleLoginDestination = (
  checkExternalDestination: Function,
  addError: Function,
  destinationOrPath: Function,
  isUrlAbsolute: Function,
  errorMessages: ErrorMessages,
  appUrl: string
) => async () => {
  // Make sure we do not run this function twice during the same request by
  // checking the __destinationRedirectProcessed flag set on the window.
  if (
    typeof window.__destinationRedirectProcessed === 'undefined' ||
    !window.__destinationRedirectProcessed
  ) {
    window.__destinationRedirectProcessed = true;
    // After the login, try to go to a destination set in the local storage,
    // if any. Otherwise, just go to the frontpage. But, before redirecting,
    // make sure that the destination, if external, is recognized as valid by
    // the backend app.
    let finalDestination = destinationOrPath('/domui/', false, false);
    if (
      isUrlAbsolute(finalDestination) &&
      !finalDestination.startsWith(appUrl)
    ) {
      try {
        finalDestination = await checkExternalDestination(finalDestination)();
      } catch ({ destination }) {
        addError(errorMessages.invalidDestination);
        finalDestination = destination;
      }
    } else {
      finalDestination = destinationOrPath('/domui/');
    }
    navigate(finalDestination);
  }
};
