import React, { useCallback, useEffect, useState } from 'react';

import { ApolloClient, InMemoryCache, useApolloClient } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import queryString from 'query-string';

import { OneColumn } from '@components/Layouts/OneColumn';
import LoginModal from '@components/Modals/LoginModal';
import OktaSignInWidget from '@presenters/web/pages/Login/OktaSignInWidget';
import { MaintenanceMode } from '@presenters/web/pages/MaintenanceMode';

import LoginDestinationHelper from './LoginDestinationHelper';

import {
  ErrorMessages,
  MAPPING_NOT_AVAILABLE_ERROR,
  SuccessData,
} from '@domain/auth';
import { getLoginErrorMessages } from '@domain/auth/getLoginErrorMessages';

import { getAuthenticationError } from '@use-cases/auth';
import { compareClientServerTime } from '@use-cases/login/serverTime';
import { useNotifications } from '@use-cases/notifications';

import { prepHandleSignInError } from '@repositories/auth/activationEmail';
import {
  authenticateUserBackendApp,
  authenticateUserWebApp,
} from '@repositories/auth/authExternalApps';
import { checkExternalDestinationCurried } from '@repositories/auth/externalDestination';
import { prepHandleLoginDestination } from '@repositories/auth/prepHandleLoginDestination';
import { DrupalError, DrupalResponse } from '@repositories/auth/typings';
import { useBasicInfo } from '@repositories/basicInfo';
import { useMaintenanceModeCheck } from '@repositories/maintenance-mode/get-maintenance-mode';

import { FEATURE_LOGIN_AUTH_CONTROLLER, isEnabled } from '@utils/features';
import { getMessageByLoginStatus } from '@utils/getMessageByLoginStatus';
import {
  destinationOrPath,
  isLanguageAvailable,
  localizedNavigate,
} from '@utils/localized-navigate';
import { isUrlAbsolute } from '@utils/url';

import { DOMINO_LOGIN_FLAG, DOMINO_ROUTE_FLAG } from '@domui-domain/constants';
import { useAppConfig as useDomuiAppConfig } from '@domui-hooks/appConfig';
import { useFetchBasicInfo } from '@domui-hooks/useFetchBasicInfo';
import { useFetchDominoMetaConfig } from '@domui-hooks/useFetchDominoMetaConfig';
import { prepHandleLoginDestination as preHandleDominoLoginDestination } from '@domui-repositories/auth/prepHandleLoginDestination';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useLogger } from '@hooks/logger';

import { DIS } from '@typings/dis';
import { Status, UserAuthenticate } from '@typings/express-responses';
import { OktaTokenErrors } from '@typings/okta';
import { LogLevel } from '@typings/operations';

const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';

let errorMessages: ErrorMessages = {};

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const checkMaintenanceMode = useMaintenanceModeCheck();
  const { addLog } = useLogger();
  const [basicInfo] = useBasicInfo();
  errorMessages = { ...errorMessages, ...getLoginErrorMessages(t) };
  const { addError, purgeNotifications } = useNotifications();
  const {
    user,
    setUser,
    clearUser,
    refetch,
    logout,
    setProcessingLogin,
    oktaWidget: { clearTokens, setTokens, usernameUsedForLogin },
  } = useAppConfig();

  const { refetch: domuiRefetch } = useDomuiAppConfig();
  const client = useApolloClient() as ApolloClient<InMemoryCache>;
  const checkExternalDestination = checkExternalDestinationCurried(i18n);
  const handleLoginDestination = prepHandleLoginDestination(
    checkExternalDestination,
    addError,
    destinationOrPath,
    isUrlAbsolute,
    errorMessages,
    appUrl
  );
  const handleDominoLoginDestination = preHandleDominoLoginDestination(
    checkExternalDestination,
    addError,
    destinationOrPath,
    isUrlAbsolute,
    errorMessages,
    appUrl
  );
  const { fetchDominoMetaConfig } = useFetchDominoMetaConfig();
  const { fetchBasicInfo } = useFetchBasicInfo();

  // This is a stripped version of the example. To checkout more of what you can
  // do with the widget: https://developer.okta.com/quickstart-fragments/widget/default-example/
  const handleSuccess = async (
    { idToken, accessToken }: SuccessData,
    renderSignInWidget: () => void
  ) => {
    setTokens({
      idToken,
      accessToken,
    });

    // Get first the access and the id tokens.
    try {
      setProcessingLogin(true);
      const dominoLogin = await fetchDominoMetaConfig(DOMINO_LOGIN_FLAG);

      const dominoRoute = await fetchDominoMetaConfig(DOMINO_ROUTE_FLAG);

      const dominoLoginFlag =
        String(dominoLogin[DOMINO_LOGIN_FLAG])?.toLowerCase() === 'true';
      const dominoRouteFlag =
        String(dominoRoute[DOMINO_ROUTE_FLAG])?.toLowerCase() === 'true';

      const { login } = user || {};
      const email = login || idToken.claims?.email;

      if (typeof idToken.claims?.name === 'string') {
        const [firstName, lastName] = idToken.claims.name.split(' ');
        setUser({
          login: login || idToken.claims.email,
          isLoggedIn: true,
          attributes: {
            firstName,
            lastName,
          },
        });
      }

      const backend = await authenticateUserWebApp(
        accessToken.accessToken,
        idToken.idToken,
        addLog,
        {
          initialCall: 'Login',
          email,
          id: user?.id,
        }
      )();

      const authControllerEnabled = isEnabled(FEATURE_LOGIN_AUTH_CONTROLLER);
      let userInfo = null;
      let drupal: DrupalResponse = Status.OK;
      const {
        isOktaIndividualId,
        redirect,
        status,
      } = backend as UserAuthenticate;

      if (redirect) {
        window.localStorage.setItem('destination', redirect);
      }
      if (status !== Status.OK) {
        drupal = status || Status.CANNOT_AUTH_USER;
      } else if (isOktaIndividualId) {
        if (authControllerEnabled) {
          if (dominoLoginFlag) {
            const basicUserInfo = await fetchBasicInfo(email);
            userInfo = (basicUserInfo as unknown) as DIS.SSOTicketResponse;
          } else {
            const basicUserInfo = await basicInfo({
              variables: {
                emailId: email,
              },
            });
            userInfo = (basicUserInfo?.data
              ?.basicInfo as unknown) as DIS.SSOTicketResponse;
          }
        }

        if (userInfo === null && authControllerEnabled) {
          drupal = Status.AUTH_CONTROLLER_ERROR;
          window.localStorage.setItem('destination', '');
        } else {
          drupal = await authenticateUserBackendApp(
            i18n.language,
            idToken.idToken,
            addLog,
            {
              initialCall: 'Login',
              email,
              id: user?.id,
            },
            userInfo
          )();
        }
      }
      // MRX-1118 If the Okta account is not yet mapped to a DIS
      // individual, we should not try to authenticate the user in Drupal.
      // This will be done later, after the account mapping is finished.
      window.localStorage.setItem('idToken', idToken.idToken);

      // Will do status calculation after making individual call to WebApp and DrupalApp
      if (drupal === Status.OK) {
        // Track a successful login
        // TODO if we need additional user data, we'll have to move this till after
        //  the useGetAnalyticsIndividualQuery hook is called in the auth hook
        // tell Adobe Launch that the user is now logged in
        window.rotaryDDO.userData.loginStatus = 'logged in';
        if (isOktaIndividualId && authControllerEnabled) {
          axios
            .get(
              `${process.env.GATSBY_BACKEND_APP_BASE_URL}/${i18n.language}/restapi/account/update`,
              {
                withCredentials: true,
              }
            )
            .then(async response => {
              addLog({
                level: LogLevel.Info,
                message: `Response from ${process.env.GATSBY_BACKEND_APP_BASE_URL}/${i18n.language}/restapi/account/update: ${response.data}`,
              });
            })
            .catch(error => {
              addLog({
                level: LogLevel.Error,
                message: `Error from ${
                  process.env.GATSBY_BACKEND_APP_BASE_URL
                }/${i18n.language}/restapi/account/update: ${
                  (error as AxiosError).message
                }: ${(error as AxiosError).stack}`,
              });
            });
        }
        if (dominoRouteFlag) {
          handleDominoLoginDestination();
          domuiRefetch();
        } else {
          handleLoginDestination();
          refetch();
        }
      } else if (
        [
          Status.DATE_INCOMPATIBLE,
          Status.RENEW_ACCOUNT,
          Status.CANNOT_AUTH_USER,
        ].includes(status as Status)
      ) {
        clearTokens();
        renderLoginErrorMessage(getMessageByLoginStatus(t, status as Status));
        renderSignInWidget();
        return;
      } else if ([Status.AUTH_CONTROLLER_ERROR].includes(drupal as Status)) {
        const {
          error_code: errorCode,
          error_message: errorMessage,
        } = (status as unknown) as DrupalError;
        addLog(getAuthenticationError(errorMessage, errorCode));
        clearTokens();
        logout();
        renderLoginErrorMessage(
          getMessageByLoginStatus(t, Status.AUTH_CONTROLLER_ERROR)
        );
        return;
      } else {
        // If we get here, something is wrong with the authentication. We
        // should have the error code and error messages available in the
        // result. In this case, we just re-render the sign in widget and
        // display an error.
        const {
          error_code: errorCode,
          error_message: errorMessage,
        } = (status as unknown) as DrupalError;
        addLog(getAuthenticationError(errorMessage, errorCode));

        clearTokens();
        renderLoginErrorMessage(
          getMessageByLoginStatus(t, Status.CANNOT_AUTH_USER)
        );
        renderSignInWidget();
      }
    } catch (e) {
      clearTokens();

      if ((e as Error).message === OktaTokenErrors.JWT_ISSUED_IN_FUTURE) {
        renderLoginErrorMessage(
          getMessageByLoginStatus(t, Status.DATE_INCOMPATIBLE)
        );
        renderSignInWidget();
        return;
      }
      addLog({
        level: LogLevel.Error,
        message: (e as Error).message,
      });
      localizedNavigate('/error');
    } finally {
      setProcessingLogin(false);
    }
  };

  const handleAfterRender = () => {
    document.querySelector('.js-help-link')?.removeAttribute('target');
  };

  const handleError = prepHandleSignInError({
    client,
    addLog,
    purgeNotifications,
    addError,
    t,
    i18n,
    usernameUsedForLogin,
  });

  const renderLoginErrorMessage = (message?: string): void => {
    clearUser();
    if (message) {
      purgeNotifications();
      addError(message);
    }
  };

  useEffect(() => {
    const mappingError = localStorage.getItem(MAPPING_NOT_AVAILABLE_ERROR);

    if (mappingError) {
      addError(mappingError);
      localStorage.removeItem(MAPPING_NOT_AVAILABLE_ERROR);
    }
  }, []);

  const languageAvailable = isLanguageAvailable();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const isCorrectClientDate = useCallback(async () => {
    const clientDate = moment().toISOString();

    addLog({
      level: LogLevel.Info,
      message: `[Time Check] Client date is ${clientDate}`,
    });

    const { data } = await compareClientServerTime(clientDate);
    addLog({
      level: LogLevel.Info,
      message: `[Time Check] Result is ${JSON.stringify(data)}`,
    });

    if (!data.isCorrectClientDate) {
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    isCorrectClientDate();
  }, []);

  useEffect(() => {
    if (languageAvailable) {
      // Set the destination in the local storage, but only if we did not
      // already process the redirect.
      // @tooo: Make this more generic maybe.
      if (
        typeof window.__destinationRedirectProcessed === 'undefined' ||
        !window.__destinationRedirectProcessed
      ) {
        // Remove a potential leftover destination from the local storage. But
        // make sure to not do this if we have Okta tokens in the hash.
        // Otherwise we remove the correct destination before it is used.
        if (!window.location.hash) {
          window.localStorage.removeItem('destination');
        }

        const queryParams = queryString.parse(window.location.search);
        if (
          queryParams.destination &&
          typeof queryParams.destination === 'string'
        ) {
          window.localStorage.setItem('destination', queryParams.destination);
        }
        if (
          queryParams.ph_return_path &&
          typeof queryParams.ph_return_path === 'string'
        ) {
          window.localStorage.setItem(
            'destination',
            `/member-center/rotary-global-rewards/offers#${queryParams.ph_return_path}`
          );
        }
      }
    }
  }, [
    i18n.language,
    languageAvailable,
    checkMaintenanceMode?.data?.maintenanace,
  ]);

  if (checkMaintenanceMode?.data?.maintenanace === 1) {
    return <MaintenanceMode />;
  }

  return (
    <div>
      {user?.isLoggedIn && user?.individualId ? (
        <LoginDestinationHelper
          handleLoginDestination={() => handleLoginDestination()}
        />
      ) : (
        <OneColumn>
          <Helmet>
            <link
              href="https://global.oktacdn.com/okta-signin-widget/5.9.2/css/okta-sign-in.min.css"
              type="text/css"
              rel="stylesheet"
            />
          </Helmet>

          <OktaSignInWidget
            onSuccess={handleSuccess}
            onError={handleError}
            onAfterRender={handleAfterRender}
          />

          <LoginModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            title={t(
              'auth.login.date-incompatible-modal-title',
              'Your Computer Clock May be Incorrect'
            )}
            text={t(
              'auth.login.date-incompatible-modal-text',
              `It appears that the time reported  by our servers and your device
              do not agree. This can cause a problems when login into My Rotary.
              Please check that the clock on your device (including time zone) 
              is set accurately and reload this page`
            )}
            continueButtonLabel={t(
              'auth.login.date-incompatible-modal-button-text',
              'Continue Anyway'
            )}
          />
        </OneColumn>
      )}
    </div>
  );
};

export default LoginPage;
