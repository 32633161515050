import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import AUTHORIZER from '@domui-utils/workflow/authorizer';

// Custom hook to handle API calls
export const useFetchBasicInfo = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(null);

  const fetchBasicInfo = async (onlineId: string) => {
    setLoading(true);
    setError(null); // Clear error before new request
    let basicInfo = null;

    try {
      const service = new AUTHORIZER();
      basicInfo = await service.getBasicInfo(onlineId);
      setData(basicInfo);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
    return basicInfo;
  };

  return { data, loading, error, fetchBasicInfo };
};
