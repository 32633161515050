import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Metadata from '@domui-utils/workflow/metadata';

// Custom hook to handle API calls
export const useFetchDominoMetaConfig = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(null);

  const fetchDominoMetaConfig = async (metaKey: string) => {
    setLoading(true);
    setError(null); // Clear error before new request
    let metaValue = null;

    try {
      const metadataWfService = new Metadata();
      metaValue = await metadataWfService.getDominoMetaConfig(metaKey);
      setData(metaValue);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
    return metaValue;
  };

  return { data, loading, error, fetchDominoMetaConfig };
};
